<template>
  <div class="page-content container-fluid">
    <div class="card borderless">
      <div class="card-header">
        <!-- <div class="form-row">
        </div> -->
        <div class="row">
          <div class="col-md-2">
            <div class="form-group mb-0">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('sms.history.lbl-search-id')"
                  v-model="form.id"
                  aria-label
                  aria-describedby="basic-addon2"
                />
                <!-- Pesquisar por ID -->
                <div class="input-group-append">
                  <button
                    class="btn icon_btn m-0"
                    type="button"
                    @click="fetch(form.page)"
                  >
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <template>
              <div>
                <date-range-picker
                  class="form-control"
                  value
                  :options="options"
                  @change="changeDate"
                />
              </div>
            </template>
          </div>
          <div class="col-lg-2 col-md-2">
            <input
              class="form-control"
              :placeholder="$t('generic-str.lbl-sender')"
              v-model="form.from"
            />
            <!-- Remetente -->
          </div>
          <div class="col-lg-2 col-md-2">
            <input
              class="form-control"
              :placeholder="$t('generic-str.lbl-recipient')"
              v-model="form.to"
            />
            <!-- Destinatário -->
          </div>
          <div class="col-lg-2 col-md-2">
            <select class="form-control" v-model="form.status">
              <option selected value>Status</option>
              <option value="accepted">
                {{ $tc('generic-str.status.lbl-pending', 1) }}
              </option>
              <!-- Pendente -->
              <option value="rejected">
                {{ $t('generic-str.status.lbl-rejected') }}
              </option>
              <!-- Rejeitado -->
              <option value="queued">
                {{ $t('generic-str.status.lbl-queue') }}
              </option>
              <!-- Em fila -->
              <option value="sending">
                {{ $t('generic-str.status.lbl-sending') }}
              </option>
              <!-- Enviando -->
              <option value="sent">
                {{ $t('generic-str.status.lbl-sent') }}
              </option>
              <!-- Enviado -->
              <option value="failed">
                {{ $t('generic-str.status.lbl-failure') }}
              </option>
              <!-- Falha -->
              <option value="delivered">
                {{ $t('generic-str.status.lbl-delivered') }}
              </option>
              <!-- Entregue -->
              <option value="undelivered">
                {{ $t('generic-str.status.lbl-unavailable') }}
              </option>
              <!-- Indisponivel -->
              <option value="received">
                {{ $tc('generic-str.status.lbl-response', 2) }}
              </option>
              <!-- Respostas -->
            </select>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="form-group">
              <div class="input-group">
                <div class>
                  <button class="btn btn-yup-purple" @click="fetch(1)">
                    <!-- {{ $t('generic-str.filter') }} -->
                    <span class="material-symbols-outlined"> search </span>
                    <!-- Filtrar -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body block-el p-0">
        <div class="responsive-table">
          <table v-if="fetched" class="table table-borderless">
            <thead class="">
              <tr>
                <th scope="col">{{ $t('generic-str.date') }}</th>
                <!-- Data -->
                <th scope="col">{{ $t('generic-str.type') }}</th>
                <!-- Tipo -->
                <th scope="col">{{ $t('generic-str.lbl-sender') }}</th>
                <!-- Remetente -->
                <th scope="col">{{ $t('generic-str.lbl-recipient') }}</th>
                <!-- Destinatario -->
                <th scope="col">{{ $tc('generic-str.message', 1) }}</th>
                <!-- Mensagem -->
                <th scope="col">{{ $tc('generic-str.part', 2) }}</th>
                <!-- Partes -->
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="message in messages"
                :key="message.id"
                :to="`history/${message.id}`"
              >
                <td data-label="Data">
                  <div>
                    <router-link :to="`history/${message.id}`">
                      {{ message.scheduled_at }}
                    </router-link>
                  </div>
                </td>
                <td data-label="Tipo">
                  <template v-if="message.direction == 'outbound'">
                    {{ $t('generic-str.send-2') }}
                  </template>
                  <template v-else-if="message.direction == 'inbound'">
                    Resposta
                  </template>
                </td>
                <td data-label="Remetente">{{ message.from }}</td>
                <td data-label="Destinatário">{{ message.to }}</td>
                <td
                  data-label="Mensagem"
                  class="max-200"
                  v-tooltip.top="`${message.body}`"
                >
                  {{ message.body }}
                </td>
                <td data-label="Partes">{{ message.segments }}</td>
                <td data-label="Status">
                  <span
                    v-if="message.status == 'accepted'"
                    class="badge badge-light"
                    >{{ $tc('generic-str.status.lbl-pending', 1) }}</span
                  >
                  <!-- Pendente -->
                  <span
                    v-else-if="message.status == 'queued'"
                    class="badge badge-secondary"
                    >{{ $t('generic-str.status.lbl-queue') }}</span
                  >
                  <!-- Em Fila -->
                  <span
                    v-else-if="message.status == 'sending'"
                    class="badge badge-accent"
                    >{{ $t('generic-str.status.lbl-sending') }}</span
                  >
                  <!-- Enviando -->
                  <span
                    v-else-if="message.status == 'sent'"
                    class="badge badge-info"
                    >{{ $t('generic-str.status.lbl-sent') }}</span
                  >
                  <!-- Enviado -->
                  <!-- <span
                    v-else-if="message.status == 'failed'"
                    class="badge badge-danger"
                    v-tooltip.top="
                      `${$t('sms.infos.filters.status.lbl-error')} ${
                        message.error_code
                      }: ${message.error_message}`
                    "
                    >{{ $t('generic-str.status.lbl-failure') }}</span
                  > -->
                  <span
                    v-else-if="message.status == 'failed'"
                    class="material-symbols-outlined icon-failed"
                    v-tooltip.top="
                      `${$t('sms.infos.filters.status.lbl-error')} ${
                        message.error_code
                      }: ${message.error_message}`
                    "
                    >unpublished</span
                  >
                  <!-- Falha -->
                  <!-- <span
                    v-else-if="message.status == 'delivered'"
                    class="badge badge-success"
                    >{{ $t('generic-str.status.lbl-delivered') }}</span
                  > -->
                  <span
                    v-else-if="message.status == 'delivered'"
                    class="material-symbols-outlined icon-success"
                  >
                    task_alt
                  </span>
                  <!-- Entregue -->
                  <span
                    v-else-if="message.status == 'undelivered'"
                    class="badge badge-dark"
                    v-tooltip.top="
                      $t('generic-str.status.lbl-error') +
                      ` ${message.error_code}: ${message.error_message}`
                    "
                    >{{ $t('generic-str.status.lbl-unavailable') }}</span
                  >
                  <!-- Indisponivel -->
                  <span
                    v-else-if="message.status == 'received'"
                    class="badge badge-success"
                    >{{ $tc('generic-str.status.lbl-response', 1) }}</span
                  >
                  <!-- Resposta -->
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="static qt-block-ui" style="padding: 120px" />
          <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
          <br />
          <pagination :lastPage="pages" @change="fetch" />
        </div>
      </div>
      <div class="card-footer">
        <button
          @click="exportMessages"
          class="btn btn-yup-purple"
          :class="{ 'qt-loader qt-loader-mini qt-loader-left': exporting }"
        >
          {{ $t('generic-str.export') }}
          <!-- Exportar -->
        </button>
        <router-link to="/sms/exports" class="btn btn-yup-purple-outline ml-2">{{
          $t('sms.history.lbl-reports')
        }}</router-link>
        <!-- Relatórios Exportados -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import SmsService from '@/services/sms.service';
// import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
// import moment from 'moment';

export default {
  name: 'SmsHistoryTab',
  components: {
    // PageHeader,
    Pagination,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      messages: [],
      beginDate: '',
      endDate: '',
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        created_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  mounted() {
    this.changeDate();
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getMessages(this.form).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
    exportMessages() {
      this.exporting = true;
      SmsService.exportSms({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/sms/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-success {
  color: #6eda2c;
}
.icon-failed {
  color: #ff0000;
}
</style>
